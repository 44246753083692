import domReady from '@roots/sage/client/dom-ready';

// Import Bootstrap
import 'bootstrap';
import 'jquery';

import Swiper from 'swiper/bundle';

/**
 * Application entrypoint
 */
domReady(async (err) => {

  if (err) {
    // handle hmr errors
    console.error(err);
  }
  
  // application code

  jQuery('header.banner .navbar-toggler').on('click', function () {
    jQuery('body').toggleClass('mobile-menu-open');
  });

  jQuery('.nav-primary li.menu-item-has-children > a > span.chev').toggleClass('dropdown-toggle');

  jQuery('li.menu-item-has-children > a > span.chev.dropdown-toggle').on('click', function (event) {
    event.preventDefault();
    var navLink = jQuery(this);

    navLink.parent().toggleClass('seleccionado');
    navLink.parent().parent().toggleClass('seleccionado');
    navLink.toggleClass('seleccionado');
    navLink.parent().siblings('.sub-menu').slideToggle();
  });

  if (jQuery('.services-lists .special-group i.chev-special').length != 0) {
    jQuery('.services-lists .special-group i.chev-special').on('click', function (event) {
      event.preventDefault();
      var chevSpecial = jQuery(this);
  
      chevSpecial.toggleClass('folded unfolded-mobile');
      chevSpecial.parent().siblings('.special-subservices').slideToggle();
    });
  }

  if (jQuery('.lists-grid .swiper.list').length != 0) {
    const letterListCarousel = new Swiper(".swiper.list", {
      slidesPerView: 1.5,
      slidesPerPage: 1,
      spaceBetween: 12,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
      },
    });
  }

  if (jQuery('.swiper.list-wrapper').length != 0) {
    const listCarousel = new Swiper(".list-wrapper", {
      slidesPerView: 1.25,
      grid: {
        rows: 3,
      },
      spaceBetween: 12,
      slidesPerPage: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }

  if (jQuery('.swiper.image-carousel').length != 0) {
    const imageCarousel = new Swiper(".swiper.image-carousel", {
      slidesPerView: 2,
      grid: {
        rows: 2,
      },
      spaceBetween: 8,
      autoplay: {
        delay: 2500,
        stopOnLastSlide: true,
        disableOnInteraction: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        576: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 4,
          grid: {
            rows: 1,
          },
          spaceBetween: 28,
        },
        992: {
          slidesPerView: 6,
          grid: {
            rows: 1,
          },
          spaceBetween: 43,
        },
      },
    });
  }

  if (jQuery('.swiper.team-carrusel').length != 0) {
    const teamCarousel = new Swiper(".team-carrusel", {
      slidesPerView: 1.5,
      slidesPerPage: 1,
      breakpoints: {
        576: {
          slidesPerView: 2.5,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });
  }

  if (jQuery('.swiper.image-gallery').length != 0) {
    if (jQuery('.swiper.image-gallery .image-item').length > 1) {
      const galleryCarousel = new Swiper(".image-gallery", {
        slidesPerView: 1,
        slidesPerPage: 1,
        centeredSlides: true,
        spaceBetween: 28,
        autoplay: {
          delay: 2500,
          stopOnLastSlide: true,
          disableOnInteraction: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    }
  }
  
  if (jQuery('#wp-block-search-select').length != 0) {
    jQuery('#wp-block-search-select').on('change', function () {
      jQuery('#wp-block-search-link').attr('href', this.value);
      jQuery('#wp-block-search-link').prop('title', 'Ir a: ' + jQuery('#wp-block-search-select  option:selected').text());
    });
  }
  
  if (jQuery('#team-speciality-select').length != 0) {
    jQuery('#team-speciality-select').on('change', function () {
      jQuery('#team-filter-link').attr('href', this.value);
      // jQuery('#team-filter-link').trigger("click");
      window.location = jQuery('#team-filter-link').attr('href');
    });
  }

});


/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
